import {
  Table,
  Thead,
  Tr,
  Th,
  Tbody,
  Box,
  Td,
  Flex,
  Text,
  Badge,
  position,
  useToast,
} from "@chakra-ui/react"
import { useQuery } from "react-query"
import {
  getPositionsByLoginId,
  getTradingAccountOpenPositions,
} from "../../../services/main.service"
import BlockedLoader from "../../composites/blocked-loader"
import _ from "lodash"
import { AiFillDelete } from "react-icons/ai"
import { useEffect, useState } from "react"
import { IMaster } from "../../../models"
import { da, de } from "date-fns/locale"
import socket from "../../../socket"
import { v4 as uuidv4 } from "uuid"

const captions = [
  "Position Id",
  "Volume",
  "Symbol",
  "Price",
  "Rate Margin",
  "Login ID",
  "SL",
  "TP",
  "Market Ask",
  "Market Bid",
  "Action",
  "Entry",
  "Status",
]

const renderKeys = [
  "positionId",
  "volume",
  "symbol",
  "price",
  "rateMargin",
  "loginID",
  "sl",
  "tp",
  "marketAsk",
  "marketBid",
  "action",
  "entry",
  "status",
]

const renderEntry = (a: number) => {
  return (
    <>
      <Text fontSize="md" fontWeight="bold" minWidth="100%">
        {a === 0 ? "IN" : "OUT"}
      </Text>
    </>
  )
}

const renderAction = (a: number) => {
  return a === 0 ? (
    <Badge colorScheme="green"> BUY</Badge>
  ) : (
    <Badge colorScheme="red"> SELL </Badge>
  )
}

const renderStatus = (a: number) => {
  return <Badge colorScheme="red"> Pending</Badge>
}

export const TradeRequests = ({ master }: { master: IMaster }) => {
  const [tradeRequests, setTradeRequests] = useState<any[]>([])
  const toast = useToast()

  const {
    data,
    isLoading,
    isError,
    isRefetching,
    isFetched,
    isFetchedAfterMount,
    refetch,
  } = useQuery(
    [`master-positions-${master.LoginID}`],
    () => getPositionsByLoginId(`${master.LoginID}`),
    {
      refetchOnWindowFocus: true,
    }
  )

  useEffect(() => {
    socket.on("NEW_POSITION_CREATED", function (posObj) {
      console.log("NEW POSITIONM CRETAE", posObj)

      if (
        "positionId" in posObj &&
        "loginID" in posObj &&
        posObj?.loginID === master?.LoginID
      ) {
        toast({
          title: "Position created.",
          description: "A new position has been created",
          status: "success",
          duration: 9000,
          isClosable: true,
          position: "top-right",
        })
        setTradeRequests((_t) => [
          {
            ...posObj,
            id: uuidv4(),
          },
          ..._t,
        ])
      } else {
        console.log(
          "positionId" in posObj,
          "loginID" in posObj,
          posObj?.loginID === master?.LoginID
        )
      }
    })
  }, [])

  useEffect(() => {
    console.log(data)
    if (data?.positions) {
      setTradeRequests(
        _.map(data?.positions ?? [], (_pos) => {
          return {
            ..._pos,
            id: uuidv4(),
          }
        })
      )
    }
  }, [data])

  if (isLoading)
    return (
      <BlockedLoader isBlocked={isLoading}>
        <></>
      </BlockedLoader>
    )

  if (isError && !tradeRequests?.length)
    return <>Can't connect to the MT5 server at present</>

  if (!tradeRequests?.length) return <> No details found for the Login ID</>

  const formatOrders = (_orders: any[]) => {
    //72535 sell 0.05 XAUUSD 1916.28
    //
    return _.map(_orders, (_o) => {
      const oArray = _o?.print?.split(" ")
      return {
        orderId: oArray[0],
        action: oArray[1],
        volume: oArray[2],
        symbol: oArray[3],
        price: oArray[4],
      }
    })
  }

  return (
    <>
      <SlavesGrid>
        {!tradeRequests || tradeRequests?.length ? (
          <>
            {_.map(tradeRequests ?? [], (deal) => {
              return <DealsTableRow key={deal?.id} deal={deal} />
            })}
          </>
        ) : (
          <>
            <Tr>
              <Td colSpan={captions?.length}>
                <Box p={4} textAlign="center">
                  <Text color="text.regular">No open positions found</Text>
                </Box>
              </Td>
            </Tr>
          </>
        )}
      </SlavesGrid>
    </>
  )
}

export default TradeRequests

const SlavesGrid = ({ children }: { children: React.ReactNode }) => {
  return (
    <>
      <Box overflowX="auto">
        <Table variant="simple" color="text.regular" border={1}>
          <Thead>
            <Tr my=".8rem" ps="0px">
              {captions.map((caption, idx) => {
                return (
                  <Th
                    color="gray.400"
                    key={idx}
                    ps={idx === 0 ? "0px" : "0px"}
                    textAlign={caption === "PL" ? "right" : "unset"}
                  >
                    {caption}
                  </Th>
                )
              })}
            </Tr>
          </Thead>
          <Tbody>{children}</Tbody>
        </Table>
      </Box>
    </>
  )
}

// "action",
// "entry",
// "status",

const DealsTableRow = ({ deal }: { deal: any }) => {
  const renderBasedOnKey = (_k: string) => {
    switch (_k) {
      case "action":
        return renderAction(deal[_k])

      case "entry":
        return renderEntry(deal[_k])

      case "status":
        return renderStatus(deal[_k])

      default:
        return (
          <Text fontSize="md" fontWeight="bold" minWidth="100%">
            {deal[_k]}
          </Text>
        )
    }
  }

  return (
    <>
      <Tr>
        {_.map(renderKeys, (_key) => {
          return (
            <Td pl={1} pr={2}>
              <Flex align="center" py=".8rem" minWidth="100%" flexWrap="nowrap">
                {renderBasedOnKey(_key)}
              </Flex>
            </Td>
          )
        })}
      </Tr>
    </>
  )
}
